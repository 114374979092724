import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Close, Delete, Edit } from "@mui/icons-material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import FileUpload from "react-material-file-upload";
import {
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  SelectionSettingsModel,
  Sort,
  TextWrapSettingsModel,
} from "@syncfusion/ej2-react-grids";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  deleteClientFileById,
  setClientFileCategory,
  updateClientFileNameById,
} from "../../../../redux/features/client-main/client-slice";
import { setClientFilesDataChange } from "../../../../redux/features/client-main/client-list-slice";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";

import CommonService, { FileCategory } from "../../../../service/common.service";

import DeleteConfirmation from "../../../../layouts/delete-confirmation/DeleteConfirmation";

import { getFileType } from "../../../../utils/FileUtils";
import { FileModelType } from "../../../../types/files";

import { ReactComponent as Download } from "../../../../assets/images/images-svg/Download.svg";

import "@cyntler/react-doc-viewer/dist/index.css";
import { filter } from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IClientSubFilesList {
  clientId: string;
}

const ClientSubFilesList: React.FC<IClientSubFilesList> = ({ clientId }) => {
  // #region variables region

  //files modal controller
  const [filesModal, setFilesModal] = useState<boolean>(false);

  //file name edit modal
  const [fileNameEdit, setFileNameEdit] = useState<boolean>(false);

  //file name
  const [fileName, setFileName] = useState<{ id: string; fileName: string }>({
    id: "",
    fileName: "",
  });

  // files
  const [newFiles, setNewFiles] = useState<any[]>([]);

  //handles preview modal open
  const [open, setOpen] = useState<boolean>(false);

  //stores url
  const [url, setUrl] = useState<string>();

  const [deleteFile, setDeleteFile] = useState<{
    openPopup: boolean;
    fileId: string;
  }>({
    openPopup: false,
    fileId: "",
  });
  //dispatch for redux
  const dispatch = useAppDispatch();

  //files
  const allClientFiles = useAppSelector((state) => state.client.clientFiles);
  //exclude assessment files
  const clientFiles = useAppSelector((state) =>
    allClientFiles?.filter((item) => item.category !== FileCategory.Assessment)
  );
  const sortedFiles = () => {
    const data = clientFiles?.sort((a, b) => {
      return (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any);
    });
    return data;
  };

  const [errorMessage, setErrorMessage] = useState<string>("");

  // #endregion

  // #region methods region
  const handlePreviewModal = async (url: string) => {
    setUrl(url);
    setOpen(true);
  };

  // category update handler
  const handleChange = async (
    e: SelectChangeEvent<string>,
    file: any,
    index: number
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await CommonService.updateFileCategory(
        file.id,
        file.entityId,
        e.target.value
      );
      if (status) {
        dispatch(setClientFileCategory({ id:file.id, value: e.target.value }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      console.log("exception: ", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  // file submit handler
  const onSubmit = async () => {
    if (newFiles.length > 0) {
      setFilesModal(false);
      setErrorMessage("");
      try {
        dispatch(setResponseValue({ name: "pending", value: true }));
        const { status, message } = await CommonService.postFiles(
          "Intake",
          "Client",
          FileModelType.Client,
          clientId,
          newFiles
        );

        if (status) {
          setNewFiles([]);
          dispatch(setClientFilesDataChange());
          setNewFiles([]);
          dispatch(setResponseValue({ name: "success", value: true }));
          dispatch(setResponseValue({ name: "message", value: message }));
        }
      } catch (e: any) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(setResponseValue({ name: "message", value: e?.message }));
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    } else {
      setErrorMessage("Please select a file");
    }
  };

  const shortenFileName = (fileName: string, maxLength: number) => {
    if (fileName.length <= maxLength) {
      return fileName;
    }

    const truncatedName = fileName.substring(0, maxLength - 3) + "...";
    return truncatedName;
  };
  //#region grid region
  let gridInstance: GridComponent;
  // let grid: Grid | null;

  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };

  //text-wrap for grid
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };

  const rowSelected = () => {
    if (gridInstance) {
      const selectedrowindex: number[] = gridInstance.getSelectedRowIndexes();
      const selectedrecords: object[] = gridInstance.getSelectedRecords();
    }
  };
  // const pdfExportComplete = (): void => {
  //   if (gridInstance) {
  //     gridInstance.hideSpinner();
  //   }
  // };
  // const excelExportComplete = (): void => {
  //   if (gridInstance) {
  //     gridInstance.hideSpinner();
  //   }
  // };

  //select format for grid
  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  //field: UploadedBy
  const uploadByField = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.uploadedBy}</span>
      </div>
    );
  };

  //field: UploadTime
  const uploadTimeField = (props: any) => {
    return (
      <div>
        <span style={{ display: "flex", justifyContent: "left" }}>
          {new Date(props.uploadedAt).toLocaleString()}
        </span>
      </div>
    );
  };

  //field: category
  const categoryField = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Box className="fromGroup">
          <Select
            className="form-control"
            labelId="demo-simple-select-label"
            value={props.category}
            name="category"
            onChange={(e: SelectChangeEvent<string>) =>
              handleChange(e, props, props.index)
            }
            MenuProps={MenuProps}
            style={{ maxWidth: "200px", width: "200px" }}
          >
            {Object.keys(FileCategory).map((category: string, index: number) => {
              const value = FileCategory[category as keyof typeof FileCategory];
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              );
            })}
            {/* <MenuItem value="Intake">Intake</MenuItem>
            <MenuItem value="Insurance">Insurance</MenuItem>
            <MenuItem value="Authorization">Authorization</MenuItem>
            <MenuItem value="Clinical">Clinical</MenuItem> */}
          </Select>
        </Box>
      </div>
    );
  };

  //field: fileName
  const fileNameField = (props: any) => {
    return (
      <div>
        <span>
          {props.fileName
            ? shortenFileName(props.fileName, 50)
            : shortenFileName(props.fileName.split("_$SPLIT$_")[1], 50)}
        </span>
      </div>
    );
  };

  //field: action
  const actionField = (props: any) => {
    return (
      props && (
        <div style={{ display: "flex", gap: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href={props.fileUrl}>
              <IconButton style={{ background: "transparent" }}>
                <Download />
              </IconButton>
            </a>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => setDeleteFile({ openPopup: true, fileId: props.id })}
          >
            <Delete color="primary" />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              setFileName({ id: props.id, fileName: props.fileName });
              setFileNameEdit(true);
            }}
          >
            <Edit color="primary" />
          </div>
          <Button onClick={() => handlePreviewModal(props.fileUrl)}>
            Preview
          </Button>
        </div>
      )
    );
  };

  // #endregion
  return (
    <div>
      <div className="listingTable__header d-block">
        <div style={{ position: "relative", textAlign: "right" }}>
          <Button className="addBtn" onClick={() => setFilesModal(true)}>
            + Upload Files
          </Button>
        </div>
      </div>
      <GridComponent
        id="grid" //must have!!
        className="gridTable"
        dataSource={sortedFiles()}
        // dataBound={dataBound.bind(this)}
        allowPaging={true}
        ref={(grid) => (gridInstance = grid as GridComponent)}
        statelessTemplates={["directiveTemplates"]}
        load={load}
        width="auto"
        height="650"
        allowGrouping={true}
        groupSettings={groupOptions}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        selectionSettings={selectionSettings}
        rowSelected={rowSelected}
        // allowPdfExport={true}
        // allowExcelExport={true}
        // pdfExportComplete={pdfExportComplete}
        // excelExportComplete={excelExportComplete}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            visible={false}
            headerText="ID"
            isPrimaryKey={true}
            width="150"
          />
          <ColumnDirective
            headerText="File Name"
            width="180"
            textAlign="Left"
            field="file"
            template={fileNameField}
          />
          <ColumnDirective
            headerText="Category"
            width="120"
            textAlign="Left"
            field="category"
            template={categoryField}
          />
          <ColumnDirective
            headerText="Upload Time"
            width="100"
            textAlign="Left"
            field="uploadTime"
            template={uploadTimeField}
          />
          <ColumnDirective
            headerText="Uploaded By"
            width="120"
            textAlign="Left"
            field="uploadedBy"
            template={uploadByField}
          />
          <ColumnDirective
            headerText="Action"
            width="120"
            textAlign="Left"
            field="action"
            disableHtmlEncode={false}
            template={actionField}
          />
        </ColumnsDirective>
        <Inject services={[Page, Group, DetailRow, Filter, Sort]} />
      </GridComponent>
      <Dialog open={fileNameEdit} className="largeModel">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Update Filename
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setFileNameEdit(false)}
          >
            <Close />
          </Button>
        </Box>
        <Box className="stepperForm">
          <ValidatorForm
            onSubmit={() => {
              dispatch(
                updateClientFileNameById({
                  id: fileName.id,
                  fileName: fileName.fileName,
                  clientId: clientId,
                })
              );
              setFileNameEdit(false);
            }}
          >
            <Box className="stepperForm__inner p-30px">
              <InputLabel className="fromLabel">File Name</InputLabel>
              <TextValidator
                id="fileName"
                type="text"
                name="fileName"
                value={fileName.fileName}
                onChange={(e: any) =>
                  setFileName({ ...fileName, fileName: e.target.value })
                }
                validators={["required"]}
                errorMessages={["File name is required"]}
                sx={{ width: "60%" }}
              />
            </Box>
            <Box className="stepperForm__footer b-0">
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" className="button">
                Save Changes
              </Button>
            </Box>
          </ValidatorForm>
        </Box>
      </Dialog>

      {filesModal && (
        <Dialog open={true} className="largeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Upload Files
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setErrorMessage("");
                  setFilesModal(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box className="largeModel__body p-30px">
              <Grid container spacing={3} mb={"30px"}>
                <Grid item lg={6} xs={12}>
                  <Box className="fromGroup">
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="fileUploadBlock">
                        <FileUpload
                          multiple
                          value={newFiles}
                          onChange={(e: File[]) => {
                            //Change: regarding duplicates, we should allow to upload
                            //This is the same behavior of Google Cloud File Drive.
                            setNewFiles([...newFiles, ...e]);
                            setErrorMessage("");
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="uploadProfile__img selectedIn-fileBox">
                          {newFiles &&
                            newFiles.map((file, index) => {
                              return (
                                <div className="selectedIn-file">
                                  <h1>{file.name}</h1>
                                  <span>
                                    <Delete
                                      onClick={() => {
                                        setNewFiles(
                                          newFiles.filter(
                                            (_, fileIndex) =>
                                              fileIndex !== index
                                          )
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              {errorMessage && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  {errorMessage}
                </FormHelperText>
              )}
            </Box>

            <Box className="stepperForm__footer">
              <Button
                type="submit"
                color="inherit"
                className="border-button"
                onClick={() => {
                  setErrorMessage("");
                  setFilesModal(false);
                }}
              >
                Cancel
              </Button>
              <div></div>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button className="button" onClick={onSubmit}>
                Save
              </Button>
            </Box>
          </Grid>
        </Dialog>
      )}
      {open && (
        <Dialog open={open} className="smallModel--assessment">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              Preview
            </Typography>

            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close style={{ color: "white" }} />
            </Button>
          </Box>
          <div className="forms-preview">
            {url !== undefined && (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: url,
                    fileType: getFileType(url),
                  },
                ]}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    // retainURLParams: false,
                  },
                }}
                // style={{ height: "85vh" }}
              />
            )}
          </div>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 20px",
            }}
          >
            <Button
              color="primary"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Box> */}
        </Dialog>
      )}
      {deleteFile.openPopup && (
        <DeleteConfirmation
          name={"File"}
          setModal={(value) => {
            if (value) {
              dispatch(deleteClientFileById({ fileId: deleteFile.fileId }));
            }
            setDeleteFile({
              openPopup: false,
              fileId: "",
            });
          }}
        />
      )}
    </div>
  );
};

export default ClientSubFilesList;
